<div *ngIf="errorNotifications.length > 0; else elseBlock">
    <div *ngFor="let error of errorNotifications">
        <p class="errorMessage">
          {{ error?.error }} <br>
        </p>
    </div>
</div>
<ng-template #elseBlock>
  <p class="errorMessage">
    Something went wrong, please contact Admin.
  </p>
</ng-template>