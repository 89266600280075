import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Notifications } from 'src/app/common/models/antar-api-response.model';
import * as fromStore from '../../state';


@Component( {
	selector: 'app-access-denied',
	templateUrl: './access-denied.component.html',
	styleUrls: [ './access-denied.component.scss' ]
} )
export class AccessDeniedComponent implements OnInit {
	errorNotifications: Notifications[] = [] ;
	errorStatus:string = '';
	hasValidError: boolean = false;
	constructor ( private store: Store<fromStore.State> ) { }
	ngOnInit (): void {
		this.store.pipe(
			select( fromStore.getErrorMessage )
		).subscribe( ( response:Notifications[] ) => {
			if ( response ) {
				this.errorNotifications = JSON.parse( JSON.stringify( response.flatMap( item => item.error || [] ) ) );
				this.hasValidError = this.errorNotifications.some( error => error?.error );
				if ( !this.hasValidError ) {
					this.errorNotifications = response;
				}
			}
		} );

	}
}
